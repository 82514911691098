import PropTypes from "prop-types";
import React, { Component } from "react";
import "./radio.css";

export const Radio = (props) => {
    const { selected, onChange, text, value, labelDirection = "column" } = props;
    return (
        <div
            className="modern-radio-container"
            style={{
                display: "flex",
                flexDirection: labelDirection,
            }}
            onClick={() => {
                onChange(value);
            }}
        >
            <div
                className={`radio-outer-circle ${value !== selected && "unselected"}`}
            >
                <div
                    className={`radio-inner-circle ${value !== selected &&
                    "unselected-circle"}`}
                />
            </div>
            {labelDirection == 'row' && <div>&nbsp;</div>}
            <div className="helper-text">{text}</div>
        </div>
    );
}

export const WhiteRadio = (props) => {
    const { selected, onChange, text, value, labelDirection = "column" } = props;
    return (
        <div
            className="modern-radio-container"
            style={{
                display: "flex",
                flexDirection: labelDirection,
            }}
            onClick={() => {
                onChange(value);
            }}
        >
            <div
                className={`radio-outer-circle-white ${value !== selected && "unselected"}`}
            >
                <div
                    className={`radio-inner-circle-white ${value !== selected &&
                    "unselected-circle-white"}`}
                />
            </div>
            {labelDirection == 'row' && <div>&nbsp;</div>}
            <div className="helper-text-white">{text}</div>
        </div>
    );
}
