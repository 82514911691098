import React, {useRef, useEffect, useLayoutEffect} from "react";
import {toast} from "react-toastify";
import {useCart} from "../cart";
import {Product} from "../models/product";
import Seo from "../components/seo"
import Layout from "../components/layout";
import {AnimatePresence, motion, useSpring} from "framer-motion";
import {Radio } from "../components/radio";
import {useIsMobile, usePrevious} from "../utils";
import {client} from "../client";


const Game = () => {
    const {isMobile, screenWidth} = useIsMobile();
    const toolRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const flavorRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const sourRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [worker, setWorker] = React.useState<string | null>(null);
    const [tool, setTool] = React.useState<string | null>(null);
    const [flavor, setFlavor] = React.useState<string | null>(null);
    const [sour, setSour] = React.useState<number | null>(null);
    const [products, setProducts] = React.useState<Product[]>([]);

    const prevWorker = usePrevious(worker);
    const prevTool= usePrevious(tool);
    const prevFlavor = usePrevious(flavor);

    const spring = useSpring(0, {damping: 300, stiffness: 1000});

    useLayoutEffect(() => {
        spring.onChange(latest => {
            window.scrollTo(0, latest);
        });
    }, [spring]);


    const {addItem} = useCart();

    function moveTo(to) {
        spring.set(window.pageYOffset, false);
        setTimeout(() => {
            spring.set(to);
        }, 50);
    }

        useEffect(() => {
            client.request(`
          query {
  products(first: 100, query:"product_type:咖啡豆訂閱") {
    edges {
      node {
        id
        tags
        handle
        title
        availableForSale
        variants(first: 10) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
              }
            }
          }
        }
        images(first: 10) {
          edges {
            node {
              url(transform: { 
                crop: CENTER
                maxWidth: 500,
                maxHeight: 500})
              id
              thumbnailUrl: url(transform: { 
                crop: CENTER
                maxWidth: 360,
                maxHeight: 360})
            }
          }
        }
      }
    }
}
          }
        `).then((data) => {
                setProducts(
                    data.products.edges.map((e) => Product.fromShopify(e))
                );
                console.log(
                    data.products.edges.map((e) => Product.fromShopify(e))
                );
            });

        }, [])

    useEffect(() => {
        if (prevWorker === null && worker) {
            moveTo(toolRef.current.offsetTop);
        }
    }, [worker]);
    useEffect(() => {
        if (prevTool === null && tool) {
            moveTo(flavorRef.current.offsetTop);
        }
    }, [tool]);
    useEffect(() => {
        if (prevFlavor === null && flavor) {
            moveTo(sourRef.current.offsetTop);
        }
    }, [flavor]);

    const Worker = () => (
        <motion.div>
            <div style={{
                width: '100%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '48px 0',
            }}>

                <div style={{
                    width: '68%',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    步驟 1/5
                    <div style={{fontSize: 28,}}>
                        想讓誰為你服務
                    </div>
                    <div
                        style={{width: '100%', height: 20}}
                    ></div>
                    <hr
                        style={{
                          color: "black",
                                background: 'black',
                            height: 1,
                            width: '100%',
                        }}
                    />
                    <div
                        style={{width: '100%', height: 40}}
                    ></div>
                    <div style={{
                        width: '100%',
                        flexDirection: isMobile ? 'column' : 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}>
                        <div>
                            <img src='/game/bird.svg' style={{width: 150}}/>
                            <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                                鵜鶘鳥 : 尋豆師
                            </div>
                            <Radio
                                selected={worker}
                                onChange={() => {
                                    setWorker('bird');
                                }}
                                value={'bird'}
                            />
                        </div>
                        <div>
                          <img src='/game/bear.svg' style={{width: 150}}/>
                            <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                                熊熊 : 烘豆師
                            </div>
                            <Radio
                                selected={worker}
                                onChange={() => {
                                    setWorker('bear');
                                }}
                                value={'bear'}
                            />
                        </div>
                        <div>
                          <img src='/game/dog.svg' style={{width: 150}}/>
                            <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                                狗狗 : 咖啡店長
                            </div>
                            <Radio
                                selected={worker}
                                onChange={() => {
                                    setWorker('dog');
                                }}
                                value={'dog'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );

    const Tool = () => (
        <motion.div
            ref={toolRef}
            initial={{
                opacity: 1
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                duration: 3
            }}
            style={{
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '48px 0',
        }}>

            <div style={{
                width: '68%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                步驟 2/5
                <div style={{fontSize: 28,}}>
                    最常使用的咖啡沖煮器具
                </div>
                <div
                    style={{width: '100%', height: 20}}
                ></div>
                <hr
                    style={{
                          color: "black",
                                background: 'black',
                        height: 1,
                        width: '100%',
                    }}
                />
                <div
                    style={{width: '100%', height: 40}}
                ></div>
                <div style={{
                    width: '100%',
                    flexDirection: isMobile ? 'column' : 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}>
                    <div>
                      <img src='/game/french.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            法式濾壓
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('濾壓壺');
                            }}
                            value={'濾壓壺'}
                        />
                    </div>
                    <div>
                      <img src='/game/espresso.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            義式
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('義式');
                            }}
                            value={'義式'}
                        />
                    </div>
                    <div>
                      <img src='/game/pour.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            手沖壺
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('手沖');
                            }}
                            value={'手沖'}
                        />
                    </div>
                    <div>
                      <img src='/game/aeropress.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            愛樂壓
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('愛樂壓');
                            }}
                            value={'愛樂壓'}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );

    const Flavor = () => (
        <motion.div
            ref={flavorRef}
            initial={{
                opacity: 1
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                duration: 3
            }}
            style={{
                width: '100%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '48px 0',
            }}>

            <div style={{
                width: '68%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
              步驟 3/5
                <div style={{fontSize: 28,}}>
                    喜歡的咖啡風味
                </div>
                <div
                    style={{width: '100%', height: 20}}
                ></div>
                <hr
                    style={{
                          color: "black",
                                background: 'black',
                        height: 1,
                        width: '100%',
                    }}
                />
                <div
                    style={{width: '100%', height: 40}}
                ></div>
                <div style={{
                    width: '100%',
                    flexDirection: isMobile ? 'column' : 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}>
                    <div>
                      <img src='/game/fruit.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            水果
                        </div>
                        <Radio
                            selected={flavor}
                            onChange={() => {
                                setFlavor('水果');
                            }}
                            value={'水果'}
                        />
                    </div>
                    <div>
                      <img src='/game/cetus.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            柑橘
                        </div>
                        <Radio
                            selected={flavor}
                            onChange={() => {
                                setFlavor('柑橘');
                            }}
                            value={'柑橘'}
                        />
                    </div>
                    <div>
                      <img src='/game/floral.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            花香
                        </div>
                        <Radio
                            selected={flavor}
                            onChange={() => {
                                setFlavor('花香');
                            }}
                            value={'花香'}
                        />
                    </div>
                    <div>
                      <img src='/game/sweet.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            甜味
                        </div>
                        <Radio
                            selected={flavor}
                            onChange={() => {
                                setFlavor('甜味');
                            }}
                            value={'甜味'}
                        />
                    </div>
                    <div>
                      <img src='/game/nut.svg' style={{height: 150}}/>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            堅果可可
                        </div>
                        <Radio
                            selected={flavor}
                            onChange={() => {
                                setFlavor('堅果可可');
                            }}
                            value={'堅果可可'}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );

  const SourOption = ({ id }) => id === sour ? (
    <div>
      <img src="/game/dog_head.svg" style={{ width: 60 }} />
      <div style={{ height: 12 }} />
      <div style={{fontWeight: 'bold', textAlign: 'center'}}>
        {id}
      </div>
    </div>
  ):
  (
      <div>
          <Radio
              selected={false}
              onChange={() => {
                  setSour(id);
              }}
              value={id}
          />
          <div style={{fontWeight: 'bold', textAlign: 'center'}}>
            {id}
          </div>
      </div>
    );

    const Sour = () => (
        <motion.div
            ref={sourRef}
        >
            <div style={{
                width: '100%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '48px 0',
            }}>

                <div style={{
                    width: '68%',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    步驟 4/5
                    <div style={{fontSize: 28,}}>
                        對於酸度的接受程度 ?
                    </div>
                    <div
                        style={{width: '100%', height: 20}}
                    ></div>
                    <hr
                        style={{
                          color: "black",
                                background: 'black',
                            height: 1,
                            width: '100%',
                        }}
                    />
                    <div
                        style={{width: '100%', height: 40}}
                    ></div>
                    <div style={{
                        width: '100%',
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            弱
                        </div>
                        <SourOption id={1} />
                        <SourOption id={2} />
                        <SourOption id={3} />
                        <SourOption id={4} />
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            強
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );


      const variantsToShow =
          products
            .filter((e) => {
                if (!e.tags.includes(`工具-${tool}`)) return false;
                if (!e.tags.includes(`風味-${flavor}`)) return false;
                return true;
            })
  //              .reduce((r, v) =>
  //                  [
  //                      ...r,
  //                      ...v.variants.map((e) => ({
  //                          ...v,
  //                          id: e.id,
  //                          spec: e.name,
  //                          price: e.price,
  //                      }))
  //                  ], []
  //              )
  //              );



    return (
        <div>
            <Seo title="咖啡豆小測試"/>
            <div style={{
            }}>
                <Worker/>
                <Tool key="tool"/>
                <Flavor key="flavor"/>
                <Sour key="sour" />
                        <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}>
 <div
                        style={{
                            width: '68%',
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        步驟 5/5
                        <div style={{fontSize: 28,}}>
                            加入購物車
                        </div>
                        <div
                            style={{width: '100%', height: 20}}
                        ></div>
                        <hr
                            style={{
                          color: "black",
                                height: 1,
                                background: 'black',
                                width: '100%',
                            }}
                        />
                        <div style={{display: 'flex', width: '100%', overflow: 'auto', margin: '32px 0px', justifyContent: 'center'}}>
                            {
                                variantsToShow.length > 0 ?
                                    variantsToShow.map((e) => (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: 8,
                                            alignItems: 'center'
                                        }}>
                                            <img src={e.coverUrl}
                                                 style={{width: 200, height: 200,
                                                objectFit: 'cover',
                                            }}/>
                                            <div style={{height: 8}}/>
                                            <div style={{fontWeight: 'bold', height: 32, fontSize: 14}}>
                                                {e.name}
                                            </div>
                                            <div style={{ marginTop: 16}}>
                                                {e.spec} / ${e.price}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                padding: '8px 48px',
                                                justifyContent: 'center',
                                                border: '1px solid',
                                                borderRadius: 8,
                                                margin: '24px 0px',
                                                cursor: "pointer",
                                            }} onClick={() => {
                                                toast('成功加入購物車');
                                                addItem([{
                                                    variantId: e.id,
                                                    quantity: 1,
                                                }]);
                                            }}>
                                                加入購物車
                                            </div>
                                        </div>
                                    )) : <div style={{ width: '100%', fontSize: 20, textAlign: 'center'}}>
                                        沒有符合的商品
                                    </div>
                            }
                        </div>
                        </div>               
                </div>

            </div>
        </div>
    );
};

export default Game;
